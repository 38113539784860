.blog-category-wrapper {
  margin: $spaces-mobile-xs auto;
  margin-left: $spaces-mobile-xs;
  margin-right: $spaces-mobile-xs;

  .blog-category-image-wrapper {
    width: 100%;
    object-fit: cover;
    padding-top: 75%;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .blog-category-content-wrapper {
    box-sizing: border-box;
    background-color: $background-color;
    margin: -2.5rem auto $spaces-mobile-l;
    width: calc(100% - $spaces-mobile-xl);
    box-shadow: 0 0 4px 4px $shadow-color;
    position: relative;
    padding: $spaces-mobile-s;
    h3 {
      margin-bottom: $spaces-mobile-l;
    }
  }
}

/* DEFINE SPECIAL PROPERTIES PER SCREEN */
@media screen and (max-width: $break-mobile-end) {
  .blog-category-wrapper {
    width: 20rem;
    margin-left: $spaces-mobile-xxs;
    margin-right: $spaces-mobile-xxs;
    .blog-category-image-wrapper {
      height: 240px;
    }
    .blog-category-content-wrapper {
      padding: $spaces-mobile-l;
    }

  }
}

@media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end) {
  .blog-category-wrapper {
    width: 28.75rem;
    .blog-category-image-wrapper {
      height: 345px;
    }
    .blog-category-content-wrapper {
      padding: $spaces-mobile-l;
    }
  }
}

@media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end) {
  .blog-category-wrapper {
    width: 28.75rem;
    .blog-category-image-wrapper {
      height: 345px;
    }
    .blog-category-content-wrapper {
      padding: $spaces-mobile-l;
    }
  }
}

@media screen and (min-width: $break-desk-hd-start) {
  .blog-category-wrapper {
    width: 28.75rem;
    .blog-category-image-wrapper {
      height: 345px;
    }
    .blog-category-content-wrapper {
      padding: $spaces-mobile-l;
    }
  }
}
