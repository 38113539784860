textarea, select {
  border: 1px solid $color-darkgrey;
  background-color: $color-white;
  margin-bottom: $spaces-mobile-s;
}

label {
  /*  margin-bottom: $spaces-mobile-s;
    margin-right: $spaces-mobile-xs;*/
}

input[type]:focus {
  border-color: $color-gold;
}

input[type=text].cal-icon,
input[type=text],
input[type=search],
input[type=url],
input[type=password],
input[type=email],
input[type=tel],
input[type=number],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=month],
input[type=week],
input[type=time] {
  border: 1px solid $color-darkgrey;
  height: 2.5rem;
  padding: 0 $spaces-mobile-xxs;
  margin-bottom: $spaces-mobile-s;
}

/* Date Input Type Background Image Stylings */

input[type=text].cal-icon,
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=month],
input[type=week],
input[type=time] {
  background-image: url("../../../project/assets/images/calender.png");
  background-repeat: no-repeat;
  background-position: right 8px center;
}

input[type=button] {
  width: 50%;
}

select {
  &.numberpicker {
    border: 1px solid $color-darkgrey;
    height: 2.5rem;
    width: 5rem;
    padding: 0 $spaces-mobile-xxs;
    margin-bottom: $spaces-mobile-s;
    background: $color-white;
  }
}

form {
  display: flex;
  justify-content: center;
  flex-direction: column;

  a.button {
    padding: 0 15px;
  }
  p {
    margin-bottom: $spaces-mobile-s;
  }
}

.formrow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: $spaces-mobile-s;
}

.form-number-selector {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: $spaces-mobile-s;
  .button {
    width: 2.5rem;
    height: 2.5rem;
    border-color: $color-darkgrey;
    color: $color-darkgrey;
  }
}

.newsletter--form--wrapper,
.form--wrapper {
  width: 100%;
  margin: $spaces-mobile-m auto;
  max-width: 350px;

  @media screen and (min-width: $break-desk-start){
    margin: $spaces-mobile-m 0;
  }
  input[type=text],
  input[type=email],
  input[type=submit],
  input[type=date],
  input[type=tel],
  textarea,
  select {
    width:100%;
  }

  input[type=submit] {
    width: 50%;
    height: 2.5rem;
  }
}

div.wpcf7-response-output, div.wpcf7-validation-errors {
  display: none !important;
}

span.wpcf7-not-valid-tip {
  display: none;
}

input[aria-invalid="true"], select[aria-invalid="true"] {
  color: white;
  background-color: $color-red;
}

input[aria-invalid="true"], select[aria-invalid="true"] {
  color: white;
  background-color: $color-red;
}
