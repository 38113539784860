/* Bookingoverlay for the Desktop*/
#bookingoverlay {
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 150px);
  position: fixed;
  outline: none !important;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $color-darkgrey;

  z-index: 11;

  .bookingoverlay-bg {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 12;
  }


  /* Append to right CTA button of mobileNav */

  .bookingoverlay-close {
    position: absolute;
    top: $spaces-mobile-s;
    right: $spaces-mobile-s;
    img {
      max-width: 0.75rem;
      max-height: 0.75rem;
    }
  }

  .bookingoverlay-content {
    position: relative;
    background: $color-lightgrey;

    min-width: 18rem;
    width: 25vw;
    max-width: 30vw;

    background: $color-darkgrey;
    padding: $spaces-mobile-m;
    z-index: 13;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2, p, label {
      color: $color-white;
    }
  }
}

@media screen and (max-width: $break-tab-end){
#bookingoverlay {
  height: calc(100% - 50px);
  padding-bottom: 60px;
}

  .bookingoverlay-content {
    input[type]{
      width: 90vw;
      max-width: 20rem;
    }
  }
  .bookingoverlay-close {
    display: none;
  }
}

@media screen and (min-width: $break-desk-start){
  .bookingoverlay-content {
    input[type]{
      width: 20rem;
    }
  }
}
