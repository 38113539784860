/* TODO: Check behavior on desktops!! */

@media screen and (max-width: $break-mobile-end) {
  .listitem-wrapper {
    width: 100%;
    height: 90px;
    padding-top: 5px;

    hr {
      margin: -5px 0 0 0;
      border: 0;
      height: 1px;
      background-color: $color-gold;
    }

    .listitem-content-wrapper {
      margin: 5px 0;
      height: 80px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: space-around;

      img {
        height: 80px;
        width: 100px;
        object-fit: cover;
        margin-right: 5px;
      }

      .listitem-text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          font-size: $paragraph;
        }
        p.listitem-roomtitle {
          text-transform: uppercase;
          color: $color-gold;
        }
      }

      .listitem-arrow-wrapper {
        display: flex;
        align-items: center;
      }
    }

  }
}

@media screen and (min-width: $break-tab-start) {
  .listitem-wrapper {
    display: none;
  }
}
