/* =====================================================
   Sections
   w3c.github.io/html/sections.html
   ===================================================== */

/**
 * 1. Prevent adjustment of font size on orientation change.
 */
html {
  box-sizing: border-box;
  height: 100%;
  text-size-adjust: 100%; /* 1 */
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

@media print {
  @page {
    margin: 2cm;
  }

  * {
    background: none !important;
    color: black !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
}

body {
  margin: 0;
  height: 100%;
  background-color: white;
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  color: $color-text;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
main,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-rendering: optimizeLegibility;
  font-size: inherit;
  font-weight: inherit;

  @media print {
    page-break-after: avoid;
  }
}
