@media screen and (max-width: $break-tab-end) {
  .desknav-wrapper {
    display: none;
  }
}

@media screen and (min-width: $break-desk-start) {
  .desknav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*width: 80vw;*/
    width: 77vw;

    position: fixed;
    right: 50%;
    transform: translate(50%);
  }

  .desknav {
    /*TODO: Check with Design Team */
    width: 90%;
    max-width: 58rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    ul.desknav {
      list-style-type: none;
      float: left;
      margin: 0;
      padding: 0;
      overflow: hidden;

      display: flex;
      justify-content: space-between;
    }

    li.desknav--link {
      list-style-type: none;
      text-align: center;
      float: left;
      a {
        color: $color-gold;
        text-decoration: none;
        line-height: 0;
        font-size: $headingSmall;
        font-weight: 400; /* BG DEVINE */
      }
    }
  }
}

