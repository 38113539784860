@media screen and (min-width: $break-desk-start) {
  .mobile-menu-wrapper {
    display: none;
  }
}

@media screen and (max-width: $break-tab-end) {
  .mobile-menu-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;
    cursor: pointer;

    .mobile-menu--button {
      width: 50%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-gold;
      padding: 15px 0;
      color: $color-white;
      text-decoration: none;
      text-transform: uppercase;
      font-size: $headingLarge !important;
      border: none;
      z-index:14;


      .hamburger-wrapper {
        width: 40px;
        height: 20px;
        position: relative;

        span {
          width: 100%;
          height: 1px;
          background-color: $color-white;
          transform-origin: left center;
          position: absolute;
          right: 0;
          transition: .25s ease-in-out;
          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            width: 65%;
            top: 10px;
          }
          &:nth-child(3) {
            top: 20px;
          }
        }
      }
      &.open {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: -4px;
            right: -8px;
          }
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 24px;
            right: -8px;
          }
        }
      }

      .cheeseburger-wrapper {
        display: none;
        width: 40px;
        height: 20px;
        position: relative;
        span {
          width: 100%;
          height: 1px;
          background-color: $color-white;
          transform-origin: left center;
          position: absolute;
          right: 0;
          &:nth-child(1) {
            transform: rotate(45deg);
            top: -4px;
            right: -8px;
          }
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 24px;
            right: -8px;
          }
        }
      }

    }
  }
}

#hamburger, #cheeseburger {
  box-sizing: border-box;
}

#cheeseburger {
  border-left: 2px solid white;
}

/* Styling for the panels */
.mobile-menu-panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $color-white;
  display: none;
  padding: 70px 0 50px;
  overflow-y: scroll;
  box-sizing: border-box;
  z-index:13;
  overflow-scrolling: touch;
}


