/* DEFINE BASE STYLES */
@media screen and (max-width: $break-mobile-end) {
  .teaser-list-wrapper {
    width: 100%;
    height: 90px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid $color-gold;
    position: relative;

    .pfeil {
      position: absolute;
      right: 0;
      top: 0;
      height: 90px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right:5px;
    }


    .teaser-list-image-wrapper {
      /*FIXME: Check with Design Team */
      img {
        height: 80px;
        width: 100px;
        object-fit: cover;
        margin-right: 5px;
      }
    }

    .teaser-list-content-wrapper {
      margin: 0;
      height: 80px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-around;

      h2 {
        font-size: $paragraph;
        text-transform: uppercase;
        color: $color-gold;
        font-family: $sans;
        text-align: left;
        margin: 0;
      }

      h3 {
        font-size: $paragraph;
        text-transform: uppercase;
        color: $color-gold;
        font-family: $sans;
        text-align: left;
      }

      /* Show subline */
      .teaser-list-content__teaser-text {
        color: $color-text;
        text-align: left;
      }
      /* Hide advanced text */
      .teaser-list-content__teaser-text__fadeout {
        display: none;
      }
      /* hide the button */
      a.button {
        display: none;
      }
      /* hide the package header */
      .teaser-list-content--package__header {
        display: none;
      }


    }
  }
}

@media screen and (min-width: $break-tab-start) {
  .teaser-list-wrapper {
    margin: $spaces-mobile-xs auto;
    margin-left: $spaces-mobile-xs;
    margin-right: $spaces-mobile-xs;

    .pfeil {
      display: none;
    }

    .teaser-list-image-wrapper {
      /*FIXME: Check with Design Team */
      width: 100%;
      object-fit: cover;
      padding-top: 75%;
      position: relative;
      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }

    .teaser-list-content-wrapper {
      box-sizing: border-box;
      background-color: $background-color;
      margin: -2.5rem auto $spaces-mobile-l;
      width: calc(100% - $spaces-mobile-xl);
      box-shadow: 0 0 4px 4px $shadow-color;
      position: relative;
      padding: $spaces-mobile-s;

      &.teaser-list-content-wrapper--package {
        h3 {
          margin: $spaces-mobile-xxl 0 $spaces-mobile-m;
        }
      }

      .teaser-list-content--package__header {
        background-color: $color-highlight;
        height: 2.5rem;
        display:flex;
        justify-content: center;
        align-items: center;

        h2 {
          vertical-align: central;
          margin: 0 !important;
          color: $color-white;
          font-size: $headingSmall !important;
        }

      }

      .teaser-list-content__teaser-text {
        margin: $spaces-mobile-s 0;
        text-align: center;
        color: $color-red;

        /* text fade out stuff */

        &.teaser-list-content__teaser-text__fadeout {
          width: 100%;
          height: 5em;
          overflow: hidden;
          position: relative;
          text-align: left;
          color: $color-text;
        }

        .teaser-list-content__teaser-text__fadeout__shadow {
          position: absolute;
          top: 0;
          width: 100%;
          height: 5em;
        }

        .gradient-color-white {
          background: linear-gradient(rgba(255, 255, 255, 0.001), $color-white);
        }
      }
    }
  }
}

/* DEFINE SPECIAL PROPERTIES PER SCREEN */

@media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end) {
  .teaser-list-wrapper {
    width: 28.75rem;
    .teaser-list-image-wrapper {
      height: 345px;
    }
    .teaser-list-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-list-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }
  }
}

@media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end) {
  .teaser-list-wrapper {
    width: 28.75rem;
    .teaser-list-image-wrapper {
      height: 345px;
    }
    .teaser-list-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-list-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }
  }
}

@media screen and (min-width: $break-desk-hd-start) {
  .teaser-list-wrapper {
    width: 28.75rem;
    .teaser-list-image-wrapper {
      height: 345px;
    }
    .teaser-list-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-list-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }
  }
}
