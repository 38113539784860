/* === GENERIC TYPOGRAPHY === */

body {
  font-family: $sans;
  font-weight: 300;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  text-align: center;
}
h1, h3,
.h1, .h3{
  font-family: $abril;
}

h2, h4, h5,
.h2, .h4, .h5,
p, a, .p, .a {
  font-family: $sans;
  font-weight: 600;
}

p, a, .p, .a {
  font-weight: 300;
}










/* Headings */
h1, .heading1 {
  font-size: $headingLarge;
  line-height: 1.875;
  display: block;

  @media screen and (min-width: $break-desk-start){
    font-size: $desktopHeadingLarge;
  }
}

h2, .heading2 {
  font-size: $headingSmall;
  line-height: 1.5;
  text-transform: uppercase;
  display: block;

  @media screen and (min-width: $break-desk-start){
    font-size: $desktopHeadingSmall;
  }
}

h3, .heading3 {
  font-size: $headingLarge;
  line-height: 1.5;

  @media screen and (min-width: $break-desk-start){
    font-size: $desktopHeadingSmall;
  }
}

h5, .heading5 {
  font-size: $paragraph;
  line-height: 1.25;
}













/* Paragraphs */

p, .p, li, span {
  font-size: $paragraph;
  line-height: 1.25;

  @media screen and (min-width: $break-desk-start){
    font-size: $desktopParagraph;
  }
  &.subline {
   color: $color-red;
  }

  &.footer {
    color: $color-white;
    line-height: 1.5;
  }

  &.fat {
    font-weight: 600;
  }
}

b, strong {
  font-weight: 600;
}










/* Anchors */
a, .a {
  font-size: $paragraph;
  line-height: 1.25;
  text-decoration: underline;
  color: $color-red;
  display: inline-block;

  & div {
    /*reset styles from anchor for EVERY SELECTED Element */
    display: block;
    color: $color-text;
  }


  @media screen and (min-width: $break-desk-start){
    font-size: $desktopParagraph;
  }
}







/* Lists */

ul {
  list-style: disc outside;
}

ol {
  list-style: decimal outside;
}

ol, ul {
  padding-left: $spaces-mobile-l;
  margin-left: $spaces-mobile-m;
  margin-top: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin: $spaces-mobile-m 0 $spaces-mobile-m $spaces-mobile-l;
  font-size: 90%;
}

li {
  margin-bottom: $spaces-mobile-s;
}



/* TOOLS */

.text-highlight {
  color: $color-red;
}

.text-white {
  color: $color-white !important;
}

.text-gold {
  color: $color-gold;
}

.uppercase {
  text-transform: uppercase;
}

.new-row {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}


