.iframe--map{
    width: 100%;


}


@media screen and (max-width: $break-mobile-end) {
    .iframe--map {
        height: 240px;
    }
}


@media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end) {
    .iframe--map {
        height: 345px;
    }
}


@media screen and (min-width: $break-desk-start) {

    .iframe--map {
        height: 510px;
    }

}