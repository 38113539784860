/* =====================================================
   Forms
   w3c.github.io/html/sec-forms.html
   ===================================================== */

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Change text/font properties to `inherit` in all browsers (opinionated).
 */
button,
input,
optgroup,
select,
textarea {
  margin: 0; /* 1 */
  font: inherit; /* 2 */
  text-transform: inherit; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * 1. Show the overflow in Edge.
 * 2. Show the overflow in Edge, Firefox, and IE.
 * 3. Ensure inputs inherit text colour of parent element
 */
button,
input, /* 1 */
select { /* 2 */
  overflow: visible;
  color: inherit; /* 3 */
}

/**
 * Change the cursor in all browsers (opinionated).
 */
button,
[type=submit],
label {
  cursor: pointer;
}

/**
 * 1. Correct the inability to style clickable types in iOS.
 * 2. Remove default styling (opinionated).
 */
button,
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 1 */
  background: none; /* 2 */
  border: 0; /* 2 */
  padding: 0; /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

/**
 * https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
 * 1. Reset minimum width based on content inside fieldset in
 *    WebKit/Blink/Firefox
 * 2. Adjust display mode to ensure 1 works in Firefox
 * 3. Remove default margin and border
 * 4. Fix margin-collapsing behavior coupled with rendering of legend element
 */
fieldset {
  min-width: 0; /* 1 */
  margin: 0; /* 3 */
  border: 0; /* 3 */
  padding: 0.01em 0 0; /* 4 */

  body:not(:-moz-handler-blocked) & {
    display: table-cell; /* 2 */
  }

  @media print {
    display: none;
  }
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: none; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
