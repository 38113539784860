.blogpost-meta {
  margin: $spaces-mobile-l 0;
}

.blogpost-social {
  margin: $spaces-mobile-l 0;
  max-width: 250px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  svg {
    height: 30px;
    width: auto;
  }
}
