.video-wrapper {
  position: relative;
  /* FIXED ASPECT RATIO FOR VIDEO 56.25% */
  padding-top: 56.25%;
  height: 0;
}

.video-element {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

