.button {
  font-family: $sans;
  border: 1px solid $color-gold;
  background-color: $color-white;
  color: $color-gold;
  text-transform: uppercase;
  margin: 0 auto $spaces-mobile-xs;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 2.5rem;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $color-white !important;
    background-color: $color-gold;
    text-decoration: none;
    border-color: unset;
    transition: all 0.5s ease-in-out;
  }
  &:last-child {
    margin: 0 auto;
  }
}

.button--reverse {
  border: none;
  background-color: $color-gold;
  color: $color-white;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $color-gold !important;
    background-color: $color-white;
    border: 1px solid $color-gold;
    transition: all 0.5s ease-in-out;
  }
}

@media screen and (max-width: $break-mobile-end) {
  .button {
    height: 38px;
  }
}

