.teaser-module-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 90rem;
  margin: 0 auto;
}

@media screen and (max-width: $break-mobile-end) {
  .teaser-module-wrapper {
    width: 100%;
    h2 {
      text-transform: uppercase;
      margin: $spaces-mobile-l 0;
    }
  }
}
