/* =====================================================
   Text-level semantics
   w3c.github.io/html/textlevel-semantics.html
   ===================================================== */

/**
* 1. Remove grey background on active links in IE 10.
*/
a {
  background-color: transparent; /* 1 */
  text-decoration: none;
  color: $color-highlight;
}

@media print {
  a,
  a:visited {
    color: black !important;
  }
}

/* Add the correct font size in all browsers */
small {
  font-size: 100%;
}

abbr[title] {
  text-decoration: underline dotted rgba(0, 0, 0, 0.33);

  &:hover {
    cursor: help;
  }
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
samp {
  font-family: map(fonts, family-monospace); /* 1 */
}

kbd {
  font-family: map(fonts, family-system);
}

/**
 * Prevent `sub` and `sup` elements from affecting line height in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25rem;
}

sup {
  top: -0.5rem;
}
