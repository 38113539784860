.teaser-image-wrapper {
  width: 100%;
  padding-top: 75%;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.media-wrapper {
  margin: $spaces-mobile-m auto;

  img {
    width: 100%;
    height: auto;
  }


  @media screen and (max-width: $break-mobile-end) {
    width: 100%;
  }

  @media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end){
    /* max-width: calc(100% - 52px); */
    width: 34.4rem;
    /* max-width: 548px; */
  }

  @media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end){
    /* width: 608px; */
    width: 38rem;
  }

  @media screen and (min-width: $break-desk-hd-start) {
    /* width: 922px; */
    width: 57.6rem;
  }
}



.deferredImg {
  transition: opacity 1s;
}
.deferredImg:not(.isLoaded) {
  opacity: 0;
}
