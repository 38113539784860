.service-banner-wrapper {
  width: 100%;
  background-color: $color-highlight;
  padding: $spaces-mobile-l 0;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .service-banner--icon-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .service-banner--icon-wrapper--icon {
      padding: $spaces-mobile-s;
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      /* TODO: Check if Value is okay */
      width: 8rem;

      p.service {
        font-size: $headingSmall;
        color: $color-white;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $break-mobile-end) {
  .service-banner--icon-wrapper {
    max-width: 18rem;
    .service-banner--icon-wrapper--icon {
      width: 7.5rem;
      &img{
        width: 100%;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: $break-tab-start) {
  .service-banner--icon-wrapper {
    max-width: 30rem;
    .service-banner--icon-wrapper--icon {
      &img {
        width: 100%;
        height: auto;
      }
    }
  }
}

