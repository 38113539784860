/* Leave this */
$slider-height: 0;

/* Change this value to set aspect ratio

16/9: 56.25%
4/3: 70%

 */
$slider-padding-top: 70%;


.slider-wrapper {
  margin: 0 auto;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $break-mobile-end) {
    max-width: 100%;
  }

  @media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end) {
    max-width: 100%;
  }

  @media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end) {
    /* TODO: Check with Design Team */
    width: 608px;
  }

  @media screen and (min-width: $break-desk-hd-start) {
    /* TODO: Check with Design Team */
    width: 922px;
  }
}

.slider {
  margin: 0 auto;
  max-width: 100%;
  height: $slider-height;
  padding-top: $slider-padding-top;
  position: relative;
}

.directional_nav {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
  justify-content: space-between;
  display: flex;
  align-items: center;

  .previous_btn {
    /*padding-left: $spaces-mobile-l;*/
    width: 50%;
    height: 100%;
    justify-content: flex-start;
  }

  .next_btn {
    /*padding-right: $spaces-mobile-l;*/
    width: 50%;
    height: 100%;
    justify-content: flex-end;
  }

  .previous_btn, .next_btn {
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      max-width: 40px;
      width: 8vw;
    }
  }
}



.slide_viewer {
  height: 100%;
  margin: 0;
  padding-top: $slider-padding-top;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%

}

.slide_group {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.slide {
  display: none;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  /* Size every item within .slide */
  > * {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.slide:first-child {
  display: block;
}

/*.slide:nth-of-type(1) {
  background: #ffbe61;
}

.slide:nth-of-type(2) {
  background: #d8a153;
}

.slide:nth-of-type(3) {
  background: #88633a;
}

.slide:nth-of-type(4) {
  background: rgba(74, 74, 74, 0.88);
}*/

/*.slide_buttons {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}

a.slide_btn {
  color: #474544;
  font-size: 42px;
  margin: 0 0.175em;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.slide_btn.active, .slide_btn:hover {
  color: #428CC6;
  cursor: pointer;
}

*/
