/* =====================================================
   Disabled elements
   w3c.github.io/html/disabled-elements.html
   ===================================================== */

/**
 * Use default cursor for disabled elements.
 */
[disabled] {
  cursor: default;
  opacity: 0.75;
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none !important;
}
