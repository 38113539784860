@media screen and (max-width: $break-tab-end) {
  header {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 4px 4px $shadow-color;
    background-color: $color-white;

    box-sizing: border-box;
    position: fixed;
    z-index: 99;
    top:0;

    img {
      height: 2.2rem;
      width: auto;

    }
    .language-picker-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .search-icon-wrapper {
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
        }
      }
      a {
        color: $color-gold;
        text-decoration: none;
        &.language-picker--active {
          color: $color-text;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (min-width: $break-desk-start) {
  header {
    width: 100%;
    height: 150px;
    padding: $spaces-mobile-l $spaces-mobile-xxl;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 4px 4px $shadow-color;
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
    background-color: $color-white;
    top: 0;

    /*TODO: Check how to build desktop menu in this structure */
    img {
      width: auto;
      height: 5.5rem;
    }

    a.button {
      max-width: 20rem;
      width: 20vw;
    }

    .language-picker-wrapper {
      display: flex;
      justify-content: center;

      .search-icon-wrapper {
        margin-right: 0.5rem;
        img {
          height: 25px;
          width: auto;
        }
      }


      a {
        font-size: 18px;
        color: $color-gold;
        text-decoration: none;
        &.language-picker--active {
          color: $color-text;
          font-weight: 600;
        }
      }
    }
  }
}


span.language--picker--pipe{
  margin:0 0.35rem;
}