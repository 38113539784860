.search-wrapper {
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;

  display: none;
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding: 0 $spaces-mobile-l;

  z-index: 98;

  background-color: $color-white;
  box-shadow: 0 0 4px 4px $shadow-color;

  @media screen and (max-width: $break-tab-end) {
    top: 50px;
  }

}

.search-bar {
  height: 2.5rem;
  width: 75%;
  max-width: 58rem;
  min-width: 20rem;

  button img {
    height: 1.5rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  form {
    position: relative;
    display: flex;
    justify-content: center;

    input[type]{
      padding-left: 0.5rem;
    }
  }
}
