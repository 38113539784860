$md-radio-checked-color: $color-gold;
$md-radio-border-color: $color-gold;
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;


.radio {
  margin: 16px 0;

  &.radio-inline {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-flex;
    height:$md-radio-size;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: center;
    align-items: center;

    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
    &:after {
      top: 5px;
      left: 5px;
      width:$md-radio-checked-size;
      height:$md-radio-checked-size;
      transform: scale(0);
      background:$md-radio-checked-color;
    }
  }
}

.check {
  margin: 16px 0;

  &.check-inline {
    display: inline-block;
  }

  input[type="checkbox"] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-flex;
    height:$md-radio-size;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: center;
    align-items: center;
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 0;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
    &:after {
      top: 5px;
      left: 5px;
      width:$md-radio-checked-size;
      height:$md-radio-checked-size;
      transform: scale(0);
      background:$md-radio-checked-color;
    }
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}
