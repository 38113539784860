@media screen and (min-width: $break-desk-start) {
  #mobile-nav-panel {
    display: none;
    .mobile-nav {
      display: none;
    }
  }
}
.mobile-nav--link-wrapper {
    width: 100%;
    height: 100%;

    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 10px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;

    background-color: $color-white;
    opacity: 0;
    transition: opacity .15s ease-in-out;

    overflow-y: scroll;

    /* correct sizings */
    box-sizing: border-box;

    &.show {
      opacity: 1;
      transition-delay: 0.3s;
      transition: opacity .25s ease-in-out;
    }
    .mobile-nav--link {
      width: 100%;
      border-bottom: 1px solid $color-gold;
      padding: 5px 0;
      &:last-child {
        border: none;
      }
      a {
        color: $color-gold !important;
        text-decoration: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: $headingLarge;
        }
        .active {
          color: $color-text;
        }
      }
      .mobile-nav--sub-nav-wrapper {
        display: none;
        &.mobile-nav--sub-nav--active {
          display: block;
        }
        .mobile-nav--sub-nav-link {
          .second-level {
            span {
              font-size: $headingSmall;
            }

            margin: 4vw 0 0;
            &:last-child {
              margin: 4vw 0;
            }
          }
        }
      }
    }
  }
