.close-button {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  box-sizing: border-box;
  line-height: 1.5rem;
  display: inline-block;
}
.close-button:before,
.close-button:after {
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5px;
  margin-left: -12.5px;
  display: block;
  height: 2px;
  width: 1.5rem;
  background-color: $color-lightgrey;
  transition: all 0.2s ease-out;
}
.close-button:after {
  transform: rotate(45deg);
}
.close-button:hover:before,
.close-button:hover:after {
  transform: rotate(0deg);
}
