footer {
  background-color: $color-darkgrey;
  width: 100%;
  padding: $spaces-mobile-xxl 0;
  box-sizing: border-box;
  p, a {
    color: $color-white;
  }
  .social-link-wrapper{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .footer-link-list a {
    text-decoration: none;
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: $color-white;
      transition: width 0.3s;
    }
    &:hover::after {
      width: 100%;
    }
  }
}
@media screen and (max-width: $break-mobile-end) {
  footer {
  padding: $spaces-mobile-xxl $spaces-mobile-xl 100px;
  .footer--address {
    text-align: left;
    padding: 0 0 0 12vw;
  }
    .social-link-wrapper {
      padding: 30px 40px;
      img {
        height: 40px;
      }
    }
    .footer-link-list {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 12vw;
    }

    .footer-link-list a {
      text-decoration: none;
      &::after {
        content: '';
        display: none;
        background: unset;
        transition: unset;
      }
    }

  }
}

@media screen and (min-width: $break-tab-start) {
  footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 4rem 6rem;
    .footer--address {
      text-align: center;
    }
    .social-link-wrapper {
      display: flex;
      justify-content: center;
      padding: 30px 0;
      img {
        height: 40px;
        margin: 0 $spaces-mobile-xxl;
      }
    }
    .footer-link-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      a {margin: $spaces-mobile-xs $spaces-mobile-xl}
    }
  }
}
