.cookieBannerWrapper{
    display:flex;
    left: 0;
    background-color: $color-red;
    position: fixed;
    width: 100vw;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    color: $color-white;

@media screen and (max-width: $break-tab-end) {
    padding: $spaces-mobile-m;
}

@media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end){
    padding: $spaces-mobile-xl;
    max-height: 106px;
}

@media screen and (min-width: $break-desk-hd-start) {
    padding: $spaces-mobile-xl;
    max-height: 106px;
}


.cookieTextWrapper{
    display: flex;
    max-width: 1298px;
    width: 67.604vw;
    align-self: flex-start;


    a{
        color: $color-white;
        text-decoration: underline!important;
    }


}

.closeBanner {
    margin-left: auto;
    opacity: 1;
    display: flex;
    align-items: flex-end;
    border: 1px solid $color-white;
    padding: $spaces-mobile-s $spaces-mobile-m;
    a {
    color: $color-white;

}

}
}

