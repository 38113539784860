.downloadlistitem-wrapper {
  width: 100%;
  height: 80px;

  padding: 5px;

  hr {
    margin: -5px 0 0 0;
    border: 0;
    height: 1px;
    background-color: $color-gold;
    width: 100%;
  }

  .downloadlistitem-content-wrapper {
    margin: 5px;
    height: 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-around;

    .downloadlistitem-text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }

}
