.keyframe {
  width: 100%;
  height: calc(100vh - 150px);
  max-height: 56.25vw;
  /*TODO: Check MIN-HEIGHT in MOBILE with Design Team */
  min-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  /*Section is formatted in keyframe.php themes part*/
  background-image: url("/assets/images/placeholderImages/video-keyframe.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  @media screen and (max-width: $break-tab-end){
    min-height: calc(100vh - 50px);
  }


  .keyframe-bgshade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, rgba(185, 149, 115, 0.7) 0%, rgba(0,0,0,0.7) 100%);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .keyframe-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    a {
      margin: $spaces-mobile-l auto;
    }

    .h1 {
      /*TODO: check with DesignTeam >> Larger Font for Keyframe?!*/
      font-size: 5.2vh;
    }

    .h5 {
      /*TODO: Check with Design Team*/
      font-size: 2.6vh;
      font-weight: 400;
    }
  }
}


.slideanimation{
  animation: ani ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  -webkit-animation: ani ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: ani ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -o-animation: ani ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -ms-animation: ani ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
}

@keyframes ani{
  0% {
    opacity:0;
    transform:  translate(0px,-25px)  ;
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes ani{
  0% {
    opacity:0;
    -moz-transform:  translate(0px,-25px)  ;
  }
  100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes ani {
  0% {
    opacity:0;
    -webkit-transform:  translate(0px,-25px)  ;
  }
  100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes ani {
  0% {
    opacity:0;
    -o-transform:  translate(0px,-25px)  ;
  }
  100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes ani {
  0% {
    opacity:0;
    -ms-transform:  translate(0px,-25px)  ;
  }
  100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
  }
}
