.quote-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: $spaces-mobile-m auto;


  /* TODO: Check with Design Team */

  max-width: 922px;

  img {
    height: auto;
    margin: $spaces-mobile-xl auto;
    width: 60%
  }

  hr {
    margin: $spaces-mobile-xl auto;
    max-width: 900px;
    width: 60%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $color-gold, rgba(0, 0, 0, 0));
  }
}


.quote-text {
  color: $color-highlight;
  font-style: italic;
  text-align: center;
}

.quoter {
  color: $color-text;
  text-align: center;
}

.social-link-wrapper {
  width: 100%;
}

@media screen and (max-width: $break-mobile-end){
  .quote-wrapper {
    width: calc(100% - $spaces-mobile-xxl);
    margin: $spaces-mobile-m auto;
    padding: $spaces-mobile-l $spaces-mobile-xs;
  }
  .quote-text {
    margin: 0 0 $spaces-mobile-s;
    font-size: $headingSmall;
  }
}

@media screen and (min-width: $break-tab-start) {
  .quote-wrapper {
    width: calc(100% - $spaces-mobile-xxl);
    margin: $spaces-mobile-m auto;
    padding: $spaces-mobile-l $spaces-mobile-xs;
  }
  .quote-text {
    margin: 0 0 $spaces-mobile-s;
    font-size: $headingLarge;
  }
}
