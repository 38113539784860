.accordion {

  h2.accordion-toggle {

    font-size: $paragraph;
    line-height: 0;
    text-transform: uppercase;
    text-decoration: none;
    text-align: left;
    color: $color-gold;

    width: 100%;
    border-bottom: 1px solid $color-gold;
    padding: 15px;
    margin: 0;
    background-color: $color-white;

    display: flex;
    align-items: center;
    justify-content: space-between;
    /* correct sizings */
    box-sizing: border-box;

    text-overflow: ellipsis;

    &:last-of-type {
      border: none;
    }
  }
}

.accordion--inner {
  display: none;
  padding: 15px;
  border-bottom: 1px solid $color-gold;
  background-color: $color-accordion-bg;

  .default {
    display: block;
  }

  p{
    margin: 0.5rem 0;
  }
}


span.accordion-toggle--headline {
  max-width: 90%;
}



