.historylistitem-wrapper {
  hr {
    margin: 0;
    border: 0;
    height: 1px;
    background-color: $color-gold;
    width: 100%;
  }
  .historylistitem-text {
    padding: $spaces-mobile-m $spaces-mobile-xl;
  }
}
