* {
  box-sizing: border-box;
}

.page-wrapper {
  margin-top: 150px;
  margin-bottom: 100px;
}

.module-wrapper {
  max-width: 1921px;
  margin-right: auto;
  margin-left: auto;
  margin-top: $spaces-mobile-xl;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: $break-tab-end) {
    padding: 0 $spaces-mobile-m;
  }

  @media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end){
  }

  @media screen and (min-width: $break-desk-hd-start) {
  }

}

@media screen and (max-width: $break-tab-end){
  .page-wrapper {
    margin-top: 50px;
  }
}

/* SAFARI Bounding FIX */
@media screen and (max-width: 359px){
  .page-wrapper {
    margin-top: 50px;
  }

  .module-wrapper {
    padding: 0;

    .text-wrapper {
      padding: 0 10px;
    }
    .listitem-wrapper {
      padding: 5px 10px 0 10px;
    }
  }

  body > footer > div.social-link-wrapper > a > img {
    height: 20px;
    width: auto;
  }
}
