.title-wrapper {
  width: 100%;
  margin: $spaces-mobile-xxl auto;
  text-align: center;
}

.title-wrapper > h1 {
  /* margin-bottom: calc($spaces-mobile-xxl * 2) ; */
}

.title-wrapper > h2,
.title-wrapper > h3
{
  margin-bottom: calc($spaces-mobile-xl * 2);
}


.title-wrapper > h4,
.title-wrapper > h5,
.title-wrapper > h6
{
  margin-bottom: calc($spaces-mobile-l * 2);
}


.text-wrapper {
  margin: 0 auto;
  margin-bottom: $spaces-mobile-m;
  width: 100%;

  @media screen and (max-width: $break-mobile-end) {
    width: 100%;
  }

  @media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end){
    width: 34.4rem;
  }

  @media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end){
    width: 38rem;
  }

  @media screen and (min-width: $break-desk-hd-start) {
    width: 57.6rem;
  }
}
/*

body > div.page-wrapper > div.module-wrapper > div.text-wrapper > h2 {
  margin-bottom: $spaces-mobile-xxl;
}

*/
