.mobile-nav-arrow {
  width: 3vw;
  max-width: 22px;
  transition: transform 0.25s;
  &.mobile-nav-arrow--active {
    transform: rotate(180deg);
    g {
      fill: #000;
    }
  }
}
