/* =====================================================
   Grouping content
   w3c.github.io/html/grouping-content.html
   ===================================================== */

/**
* 1. Add the correct box sizing in Firefox.
* 2. Show the overflow in Edge and IE.
*/
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
  border: 0;
}

p,
pre,
blockquote,
ul,
ol,
dl,
figure {
  margin: 0;
}

pre {
  overflow: auto;
  white-space: pre;
  hyphens: none;
  tab-size: 2;
}

blockquote {
  @media print {
    page-break-inside: avoid;
  }
}

/**
 * Add the correct display in IE 9-.
 */
figure,
figcaption {
  display: block;
}

/**
 * Default to ’bare’ lists (opinionated).
 */
ol,
ul {
  padding: 0;
  list-style: none;
}

/**
 * Default to no indenting on defintion data (opinionated).
 */
dd {
  margin: 0;
}
