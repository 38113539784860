@media screen and (max-width: $break-tab-end) {
  .inpagenav {
    display: none;
  }
}

@media screen and (min-width: $break-desk-start) {

  .inpagenav, .blognav {
    padding: $spaces-mobile-m 0;
    margin: $spaces-mobile-m auto;
    display: flex;
    justify-content: center;
    list-style-type: none;
    overflow: hidden;
    max-width: 80vw;
    flex-wrap: wrap;
    align-items: center;

  }

  li.inpagenav--link {
    list-style-type: none;
    text-align: center;
    float: left;
    margin: $spaces-mobile-xs $spaces-mobile-m;
    a {
      color: $color-gold;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 0;
      font-size: $headingSmall;
    }
  }
}


 .blognav {
  padding: $spaces-mobile-m 0;
  margin: $spaces-mobile-m auto;
  display: flex;
  justify-content: center;
  list-style-type: none;
  overflow: hidden;
  max-width: 80vw;
  flex-wrap: wrap;
  align-items: center;

}

li.inpagenav--link {
  list-style-type: none;
  text-align: center;
  float: left;
  margin: $spaces-mobile-xs $spaces-mobile-m;
a {
  color: $color-gold;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 0;
  font-size: $headingSmall;
}
}