#hero {
  display: none;
  width: 100vw;
  height: 100vh;
  background-color: $color-darkgrey;
  z-index: 700;
  position: fixed;
  top: 0;
  left: 0;
}

.hero--close-button {
  display: flex;
  justify-content: flex-end;
  margin: $spaces-mobile-m;
}

.hero--video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 80vw;
  min-height: 50.625vw;
  max-height: 50vh;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#hero iframe{
  width: auto;
  height: auto;
}
