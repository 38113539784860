.teaser-wrapper {
  margin: $spaces-mobile-xs auto;
  margin-left: $spaces-mobile-xs;
  margin-right: $spaces-mobile-xs;

  .teaser-image-wrapper {
    /*FIXME: Check with Design Team */
    width: 100%;
    object-fit: cover;
  }

  .teaser-content-wrapper {
    box-sizing: border-box;
    background-color: $background-color;
    margin: -2.5rem auto $spaces-mobile-l;
    width: calc(100% - $spaces-mobile-xl);
    box-shadow: 0 0 4px 4px $shadow-color;
    position: relative;
    padding: $spaces-mobile-s;

    &.teaser-content-wrapper--package {
      h3 {
        margin: $spaces-mobile-xxl 0 $spaces-mobile-m;
      }
    }

    &.teaser-content-wrapper--textteaser {
      background-color: $color-gold;
      margin-top: $spaces-mobile-l;

      /* set colors to white */
      h3 {
        color: $color-white;
      }
      .teaser-content__teaser-text {
        color: $color-white;
      }
      .p.text-highlight.teaser-content__teaser-text {
        color: $color-white !important;
      }

      /* different background color for textteaser */
      &-background-red {
        background-color: $color-red !important;
        margin-top: $spaces-mobile-l;

        /* set colors to white */
        h3 {
          color: $color-white;
        }
        .teaser-content__teaser-text {
          color: $color-white;
        }
        .p.text-highlight.teaser-content__teaser-text {
          color: $color-white !important;
        }

        a.button {
          color: $color-red !important;
        }
      }
    }

    .teaser-content--package__header {
      background-color: $color-highlight;
      height: 2.5rem;
      display:flex;
      justify-content: center;
      align-items: center;

      h2 {
        vertical-align: central;
        margin: 0 !important;
        color: $color-white;
        font-size: $headingSmall !important;
      }

    }

    .teaser-content__teaser-text {
      margin: $spaces-mobile-s 0;

      /* text fade out stuff */

      &.teaser-content__teaser-text__fadeout {
        width: 100%;
        height: 5em;
        overflow: hidden;
        position: relative;
      }

      .teaser-content__teaser-text__fadeout__shadow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 5em;
      }

      .gradient-color-white {
        background: linear-gradient(rgba(255, 255, 255, 0.001), $color-white);
      }
      .gradient-color-red {
        background: linear-gradient(rgba(152, 28, 48, 0.001), $color-red);
      }
      .gradient-color-gold {
        background: linear-gradient(rgba(185, 149, 115, 0.001), $color-gold);
      }
    }
  }
}

.special-teaser {
  display: flex;
  justify-content: center;
  width: 100%;
  /* make it wider than standard teasers */
  @media screen and (min-width: $break-tab-start) {
    /* TODO: Check with DesignTeam for Correct Value of width... */
    .teaser-wrapper {
      /* Values from Sketchfile */
      min-width: 608px;
      width: 45vw !important;
      max-width: 922px;
    }
  }
}

@media screen and (max-width: $break-mobile-end) {
  .teaser-wrapper {
    width: 20rem;
    margin-left: $spaces-mobile-xxs;
    margin-right: $spaces-mobile-xxs;
    .teaser-image-wrapper {
      height: 240px;
    }
    .teaser-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }

  }
}

@media screen and (min-width: $break-tab-start) and (max-width: $break-tab-end) {
  .teaser-wrapper {
    width: 28.75rem;
    .teaser-image-wrapper {
      height: 345px;
    }
    .teaser-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }
  }
}

@media screen and (min-width: $break-desk-start) and (max-width: $break-desk-end) {
  .teaser-wrapper {
    width: 28.75rem;
    .teaser-image-wrapper {
      height: 345px;
    }
    .teaser-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }
  }
}

@media screen and (min-width: $break-desk-hd-start) {
  .teaser-wrapper {
    width: 28.75rem;
    .teaser-image-wrapper {
      height: 345px;
    }
    .teaser-content-wrapper {
      padding: $spaces-mobile-l;

      .teaser-content--package__header {
        margin: -$spaces-mobile-l;
      }
    }
  }
}
